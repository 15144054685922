.bodyFour {
    width: 100%;
    height: 100%;
    background-image: url("https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/screen4_bg.webp");
    background-size: cover;
    background-repeat: no-repeat;

    .title{
        color: #222222;
        text-align: center;
        font-family: "Alibaba PuHuiTi 2.0";
        font-size: 56px;
        font-style: normal;
        font-weight: 75 SemiBold;
        line-height: 80px;
        padding-top: 4.58vw;
    }
    .subTitle{
        color: #666666;
        text-align: center;
        font-family: "Alibaba PuHuiTi 2.0";
        font-size: 34px;
        font-style: normal;
        font-weight: 55 Regular;
        line-height: 68px;       
    }

    .fourImg{
        display: block;
        width: 53.26vw;
        height: 35.42vw;
        margin: 4vw auto 0 auto;
    }
}


@media only screen and (max-width: 768px) {
    .bodyFour {
        flex-direction: column;
        padding-top: 1.49rem;

        p {
            margin: 0;
            line-height: .9rem;
            font-size: .68rem;
            white-space: nowrap;
        }
    }
}