
.footer {
    width: 100%;
    height: 17.78vw;
    flex-shrink: 0;
    background: #252525;

    .wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 2.43vw 8.33vw 1.8vw 8.33vw;

        .leftWrapper {
            margin-right: 13.23vw;

            .footerLogo {
                width: 8.82vw;
                height: 1.8vw;
                display: block;
            }

            .tel {
                color: #ffffff;
                font-family: "Alibaba PuHuiTi 2.0";
                font-size: 16px;
                font-style: normal;
                font-weight: 45 Light;
                line-height: 34px;
                margin-top: 2.15vw;
            }

            .addEmail {
                color: #d4d4d4;
                font-family: "Alibaba PuHuiTi 2.0";
                font-size: 16px;
                font-style: normal;
                font-weight: 45 Light;
                line-height: 34px;
            }
        }

        .centerWrapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            .centerItem {
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                justify-content: center;
                color: #bebebe;
                font-family: "Alibaba PuHuiTi 2.0";
                font-size: 16px;
                font-style: normal;
                font-weight: 55 Regular;
                line-height: normal;
                text-align: center;

                .centerItemImg {
                    width: 6.94vw;
                    height: 6.94vw;
                    display: block;
                    margin-bottom: 10px;
                }
            }

            .centerItem:nth-child(1) {
                margin-right: 2.08vw;
            }
        }

        .rightWrapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            margin-left: 18vw;

            .rightItem {
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                justify-content: center;
                color: #d4d4d4;
                font-family: "Alibaba PuHuiTi 2.0";
                font-size: 16px;
                font-style: normal;
                font-weight: 55 Regular;
                line-height: normal;
                text-align: center;
                opacity: 0.7;
                cursor: pointer;

                .rightItemImg {
                    width: 2.78vw;
                    height: 2.78vw;
                    display: block;
                    margin-bottom: 2px;

                }

                div {
                    flex-shrink: 0;
                    white-space: nowrap;
                }
            }

            .rightItem:nth-child(2) {
                margin: 0 2.08vw;
            }

            .rightItem:hover {
                opacity: 1;
            }
        }
    }

    .hr {
        width: 83.33vw;
        margin: 0 auto;
        height: 2px;
        background: #ffffff17;
    }

    .certificates {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding-top: 12px;
        color: #979797;
        font-family: "Alibaba PuHuiTi 2.0";
        font-size: 14px;
        font-style: normal;
        font-weight: 55 Regular;
        line-height: normal;

        div,
        a {
            position: relative;
            color: #979797;
            text-decoration: none;
        }

        a:nth-child(1) {
            margin-right: 2.78vw;

            &::after {
                content: '';
                position: absolute;
                right: -1.39vw;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 14px;
                background: #70707070;
            }
        }
    }

    .detailInfo {
        color: #707070;
        text-align: center;
        font-family: "Alibaba PuHuiTi 2.0";
        font-size: 14px;
        font-style: normal;
        font-weight: 55 Regular;
        line-height: normal;
        margin-top: 0.7vw;
    }
}