.bodyTwo {
    width: 100%;
    height: 100%;
    background-image: url("https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/screen2_bg.webp");
    background-size: cover;
    background-repeat: no-repeat;

    .titles {
        margin-top: 5.47vw;
        text-align: center;

        div:nth-child(1) {
            color: #222222;
            text-align: center;
            font-family: "Alibaba PuHuiTi 2.0";
            font-size: 68px;
            font-style: normal;
            font-weight: 75 SemiBold;
            line-height: 122.4px;
        }

        div:nth-child(2) {
            color: #666666;
            text-align: center;
            font-family: "Alibaba PuHuiTi 2.0";
            font-size: 44px;
            font-style: normal;
            font-weight: 55 Regular;
            line-height: 79.2px;
        }
    }

    .contentWrappper {
        margin-top: 6vw;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        // opacity: 0;
        // transform: translateY(50px);
        // transition: opacity 0.5s, transform 0.5s;

        .contentWrappper_left {
            width: 41vw;
            height: 25vw;
            position: relative;
            transition: all 0.3s linear;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                display: block;
                transition: all 0.3s linear;
            }
            img:hover{
                transform: scale(1.06);
            }
        }
        // .contentWrappper_left:hover{
        //     cursor: pointer;
        //     transform: scale(1.05);
        // }

        .leftContent {
            width: 100%;
            position: absolute;
            z-index: 9;
            bottom: 0;
            left: 0;
            height: 7.8vw;

            // background-color: aqua;
            .contentTitle {
                color: #ffffff;
                text-align: left;
                font-family: "Alibaba PuHuiTi 2.0";
                font-size: 30px;
                font-style: normal;
                font-weight: 75 SemiBold;
                line-height: normal;
                padding: 16px 0 0 50px;
            }

            .infos {
                padding-left: 50px;
                margin-top: 20px;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;

                .infoItem {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    color: #ffffff;
                    font-family: "Alibaba PuHuiTi 2.0";
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 55 Regular;
                    line-height: 50px;
                    img{
                        width: 24px;
                        height: 24px;
                        display: block;
                        margin-right: 6px;
                    }
                }
                .infoItem:nth-child(1) {
                    margin-right: 4.87vw;
                }
            }
        }

        .contentWrappper_right {
            width: 41vw;
            height: 25vw;
            margin-left: 1.4vw;
            position: relative;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                display: block;
                transition: all 0.3s linear;
            }
            img:hover{
                transform: scale(1.06);
            }
        }
    }

    // .contentWrappperShow{
    //     transform: translateY(0px);
    //     animation: show 0.6s ease-in;
    // }

    // @keyframes show {
    //     0% {
    //         transform: translateY(100px);
    //     }
    //     100% {
    //         transform: translateY(0px);
    //     }
    // }

    // .contentWrappperHide{
    //     transform: translateY(200px);
    //     animation: hide 0.6s ease-in;
    // }

    // @keyframes hide {
    //     0% {
    //         transform: translateY(0px);
    //     }
    //     100% {
    //         transform: translateY(100px);
    //     }
    // }
}


@media only screen and (max-width: 768px) {
    .bodyOne {
        flex-direction: column;
        padding-top: 1.49rem;

        p {
            margin: 0;
            line-height: .9rem;
            font-size: .68rem;
            white-space: nowrap;
        }
    }
}