.aboutWrapper {
    width: 100%;
    height: 14.375vw;
    flex-shrink: 0;
    background-image: url("https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/about_us.webp");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    .aboutTitle {
        color: #ffffff;
        font-family: "Alibaba PuHuiTi 2.0";
        font-size: 42px;
        font-style: normal;
        font-weight: 75 SemiBold;
        line-height: 80prpx;
    }

    .aboutSubTitle {
        color: #ffffff;
        font-family: "Alibaba PuHuiTi 2.0";
        font-size: 26px;
        font-style: normal;
        font-weight: 55 Regular;
        line-height: 60px;
    }

    .aboutRight {
        margin-left: 38.33vw;
        width: 13vw;
        height: 3.54vw;
        flex-shrink: 0;
        border-radius: 4px;
        background: #FFF;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        color: #0483ff;
        font-family: "Alibaba PuHuiTi 2.0";
        font-size: 24px;
        font-style: normal;
        font-weight: 75 SemiBold;
        line-height: normal;
        cursor: pointer;
        img{
            width: 1.4vw;
            height: 1.4vw;
        }
    }
}
