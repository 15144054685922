.room {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
}

body {
    // background: #1a1a1a;
}
