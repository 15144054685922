.container {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    width: 260px;
    height: 92px;
    border-radius: 10px;
    flex: 0 0 auto;
    align-self: flex-start;
}

.download {
    font-size: 18px;
    padding: 12px 32px;
    color: #ffb700;
    background-color: #565142;
    border-radius: 4px;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .container {
        padding: .85rem .32rem 0;
    }

    .logo {
        width: auto;
        height: 1rem;
        border-radius: 10px;
        flex: 0 0 auto;
        align-self: flex-start;
    }

    .download {
        font-size: .26rem;
        padding: .12rem .32rem;
        color: #ffb700;
        background-color: #565142;
        border-radius: .4rem;
        cursor: pointer;
    }
}
