.bodyOne {
    width: 100%;
    height: 100%;
    background-image: url("https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/bg_1.webp");
    background-size: cover;
    background-repeat: no-repeat;

    p {
        margin: 0;
        line-height: 90px;
        font-size: 68px;
        white-space: nowrap;
    }

    .logo {
        padding: 3.13vw 0 0 8.33vw;

        img {
            width: 10.6vw;
            height: 2.15vw;
            display: block;
        }
    }

    .contentWrappper {
        margin-top: 3.2vw;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        .contentWrappper_left {
            width: 37.57vw;

            img {
                width: 100%;
                height: 14.1vw;
                display: block;
            }

            .dowmloadBtn {
                width: 13vw;
                height: 3.54vw;
                border-radius: 4px;
                background: #0483FF;
                color: #ffffff;
                font-family: "Alibaba PuHuiTi 2.0";
                font-size: 28px;
                font-style: normal;
                font-weight: 75 SemiBold;
                line-height: 3.54vw;
                text-align: center;
                margin-top: 4.03vw;
                cursor: pointer;
                text-decoration: none;
                display: block;
            }
        }
        .contentWrappper_right{
            width: 42.36vw;
            height: 41.53vw;
            margin-left: 3.125vw;
        }
    }
}


@media only screen and (max-width: 768px) {
    .bodyOne {
        flex-direction: column;
        padding-top: 1.49rem;

        p {
            margin: 0;
            line-height: .9rem;
            font-size: .68rem;
            white-space: nowrap;
        }
    }
}