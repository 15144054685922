.container {
    position: relative;
    height: 100vh;
    width: 100%;
    // padding: 0 20%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    // background-color: #1a1a1a;
    color: #fff;
}

@media only screen and (max-width: 768px) {
    .container {
        position: relative;
        height: auto;
        width: 100%;
        padding: unset;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        // background-color: #1a1a1a;
        color: #fff;
    }
}
