.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, .8);
    z-index: 9;
    color: #fff;
}

path {
    animation-name: circleLoading;
    animation-duration: 1.5s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode: both;
    animation-play-state: running;
    stroke-dasharray: 2000;
}

@keyframes circleLoading {
    from {
        stroke-dashoffset: 0;
        stroke: #fff;
    }

    to {
        stroke-dashoffset: 2000;
        stroke: #FFD675;
    }
}

.text {
    position: relative;
    margin-top: 24px;
    height: min-content;
}
