.bodyThree {
    width: 100%;
    height: 100vw;
    background-image: url("https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/screen3_bg.webp");
    background-size: cover;
    background-repeat: no-repeat;

    .title {
        color: #222222;
        text-align: center;
        font-family: "Alibaba PuHuiTi 2.0";
        font-size: 60px;
        font-style: normal;
        font-weight: 75 SemiBold;
        line-height: 100px;
        padding-top: 4.6vw;
    }

    .subTitle {
        color: #666666;
        text-align: center;
        font-family: "Alibaba PuHuiTi 2.0";
        font-size: 44px;
        font-style: normal;
        font-weight: 55 Regular;
        line-height: 79.2px;
    }

    .contentWrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        margin-top: 4.8vw;
        height: 76.25vw;

        .leftWrapper {
            .leftContent{
                height: 36.39vw;
                display: flex;
                flex-flow: column nowrap;
                // align-items: center;
                justify-content: center;
            }
            .leftContentImg {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;

                img {
                    width: 2.36vw;
                    height: 2.36vw;
                }

                div {
                    color: #222222;
                    font-family: "Alibaba PuHuiTi 2.0";
                    font-size: 42px;
                    font-style: normal;
                    font-weight: 75 SemiBold;
                    line-height: 100px;
                }
            }

            .contentDetail {
                padding-left: 2.36vw;
                color: #444444;
                font-family: "Alibaba PuHuiTi 2.0";
                font-size: 30px;
                font-style: normal;
                font-weight: 55 Regular;
                line-height: 50px;
            }

            .contentDetail:nth-child(1) {
                margin-top: 30px;
            }

            .leftImg {
                width: 37.57vw;
                height: 40vw;
                // margin-top: 15.5vw;
            }
        }

        .rightWrapper {
            margin-left: 8.68vw;
            .rightImg {
                height: 40vw;
                width: 36vw;
                // margin-bottom: 12vw;
            }

            .rightContent {
                height: 36.39vw;
                display: flex;
                flex-flow: column nowrap;
                // align-items: center;
                justify-content: center;
                .rightContentImg {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;

                    img {
                        width: 2.36vw;
                        height: 2.36vw;
                    }

                    div {
                        color: #222222;
                        font-family: "Alibaba PuHuiTi 2.0";
                        font-size: 42px;
                        font-style: normal;
                        font-weight: 75 SemiBold;
                        line-height: 100px;
                    }
                }
                .contentDetail {
                    padding-left: 2.36vw;
                    color: #444444;
                    font-family: "Alibaba PuHuiTi 2.0";
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 55 Regular;
                    line-height: 50px;
                }
    
                .contentDetail:nth-child(1) {
                    margin-top: 30px;
                }
            }
        }
    }
}


@media only screen and (max-width: 768px) {
    .bodyThree {
        flex-direction: column;
        padding-top: 1.49rem;

        p {
            margin: 0;
            line-height: .9rem;
            font-size: .68rem;
            white-space: nowrap;
        }
    }
}