.about {
    width: 100%;
    background: #FFF;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    overflow-x: hidden;
    overflow-y: auto;

    .logo {
        padding: 3.13vw 0 0 8.33vw;

        img {
            width: 10.6vw;
            height: 2.15vw;
            display: block;
        }
    }

    .navigater {
        color: #999999;
        font-family: "PingFang SC";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 40rpx;
        padding: 3.33vw 0 0 8.33vw;

        .shouye{
            cursor: pointer;
        }

        .navigaterSpan {
            color: #333333;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
        }
    }

    .title {
        text-align: center;
        margin-top: 28px;
        color: #222222;
        text-align: center;
        font-family: "Alibaba PuHuiTi 2.0";
        font-size: 56px;
        font-style: normal;
        font-weight: 75 SemiBold;
        line-height: 100px;
    }
    .title2{
        margin-top: 7.3vw;
    }

    .subTitle {
        color: #666666;
        text-align: center;
        font-family: "Alibaba PuHuiTi 2.0";
        font-size: 36px;
        font-style: normal;
        font-weight: 55 Regular;
        line-height: 60rpx;
    }

    .swiperWrap {
        width: 83.33vw;
        height: 33.33vw;
        margin: 3vw auto 0 auto;
        background: #0483FF;
        position: relative;

        .hr {
            height: 3vw;
        }

        .mySwiper {
            width: 76.875vw;
            height: 27.36vw;
            margin-left: 2vw;
            // background-color: red;
            // border: 1px solid tan;

            .swiperImg {
                width: 76.875vw;
                height: 27.36vw;
                display: block;
            }

        }

        .swiperPagenation{
            position: absolute;
            bottom: 0;
            right: 3vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 33.33vw;
            width: 4px;
            z-index: 9999;
            // background-color: red;
            .pagenationItem{
                height: 1.6vw;
                width: 4px;
                background: #ffffff5c;
                margin: 0.2vw 0;
            }
            .pagenationItemActive{
                background: #fff;
            }
        }
    }

    .developHistory{
        width: 83.33vw;
        height: 26.67vw;
        display: block;
        margin: 3.5vw auto 0 auto;
    }

    .hr2{
        height: 16.11vw;
    }
}
