.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}

.body {
    font-size: 18px;
    color: #fff;
}

.icon {
    position: absolute;
    margin: auto;
    transform: rotate(45deg) translate(-25%, -50%);
    top: 25vw;
    right: 25vw;
    animation: fade ease-out 1.5s infinite;
}

@keyframes fade {
    from {
        transform: rotate(45deg) translate(-25%, -50%);
        opacity: 1;
    }

    to {
        transform: rotate(45deg) translate(-25%, -75%);
        opacity: .5;
    }
}

.close {
    position: absolute;
    bottom: 20%;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    width: min-content;
    border: 1px solid #fff;
    border-radius: 24px;
    padding: 12px 24px;
    white-space: nowrap;
}

@media only screen and (max-width: 768px) {
    .body {
        font-size: .28rem;
        color: #fff;
    }
}
