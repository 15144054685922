.screen1 {
    width: 100%;
    height: 13.36rem;

    background-image: url("https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/phone_screen1_1.webp");
    background-size: cover;
    background-repeat: no-repeat;

    .logoWrap {
        width: 2rem;
        height: 0.4rem;
        margin: 1.27rem auto 0 auto;

        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .screenImg3 {
        display: block;
        width: 6.24rem;
        height: 2.3rem;
        margin: 0.63rem auto 0 auto;
    }

    .screenImg4 {
        display: block;
        width: 7.12rem;
        height: 7.68rem;
        margin: 0.68rem auto 0 auto;
    }
}

.screenTitle {
    color: #222222;
    text-align: center;
    font-family: "Alibaba PuHuiTi 2.0";
    font-size: 0.4rem;
    font-style: normal;
    font-weight: 75 SemiBold;
    line-height: 0.68rem;
    padding-top: 1rem;
}

.screensubTitle {
    color: #666666;
    text-align: center;
    font-family: "Alibaba PuHuiTi 2.0";
    font-size: 0.3rem;
    font-style: normal;
    font-weight: 55 Regular;
    line-height: 0.51rem;
}

.screen2 {
    width: 100%;
    height: 14.7rem;
    background-image: url("https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/phone_screen2_1.webp");
    background-size: cover;
    background-repeat: no-repeat;

    .screen2Content {
        width: 6.46rem;
        height: 5.1rem;
        margin: 0.86rem auto 0 auto;
        position: relative;
        background-image: url("https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/phone_screen2_2.webp");
        background-size: cover;
        background-repeat: no-repeat;

        .contentWrap {
            position: absolute;
            left: 0.3rem;
            bottom: 0.14rem;
            width: 5.84rem;
            height: 1.38rem;

            .line1 {
                color: #ffffff;
                text-align: left;
                font-family: "Alibaba PuHuiTi 2.0";
                font-size: 0.32rem;
                font-style: normal;
                font-weight: 75 SemiBold;
                line-height: normal;
                margin: 0;
            }

            .line2 {
                color: #ffffff;
                font-family: "Alibaba PuHuiTi 2.0";
                font-size: 0.26rem;
                font-style: normal;
                font-weight: 55 Regular;
                line-height: 0.39rem;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                margin-top: 0.06rem;

                img {
                    width: 0.28rem;
                    height: 0.28rem;
                    margin-right: 0.08rem;
                }
            }

            .line3 {
                color: #ffffff;
                font-family: "Alibaba PuHuiTi 2.0";
                font-size: 0.26rem;
                font-style: normal;
                font-weight: 55 Regular;
                line-height: 0.39rem;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: flex-end;
                margin-top: 0.08rem;

                img {
                    width: 0.28rem;
                    height: 0.28rem;
                    margin-right: 0.08rem;
                }
            }
        }
    }
}

.screen3 {
    width: 100%;
    height: 22.28rem;
    background-image: url("https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/replace2.webp");
    background-size: cover;
    background-repeat: no-repeat;

    .screenImg1 {
        width: 6.64rem;
        height: 7.3rem;
        display: block;
        margin: 0.64rem auto 0 auto;
    }

    .screen3Content {
        margin-top: 0.74rem;

        .line1 {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            color: #222222;
            text-align: center;
            font-family: "Alibaba PuHuiTi 2.0";
            font-size: 0.3rem;
            font-style: normal;
            font-weight: 75 SemiBold;
            line-height: 0.54rem;

            img {
                width: 0.4rem;
                height: 0.4rem;
            }
        }

        .line2 {
            color: #555555;
            text-align: center;
            font-family: "Alibaba PuHuiTi 2.0";
            font-size: 0.28rem;
            font-style: normal;
            font-weight: 55 Regular;
            line-height: 0.42rem;
        }
    }

    .screenImg4 {
        width: 6.64rem;
        height: 7.04rem;
        display: block;
        margin: 2.62rem auto 0 auto;
    }
}

.screen4 {
    width: 100%;
    background-color: #F8F8F8;

    .screenImg1 {
        width: 5.96rem;
        height: 3.96rem;
        display: block;
        margin: 0.64rem auto 0 auto;
    }

    .content1 {
        margin: 0.4rem auto 0 auto;
        color: #222222;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.5rem;

        .divHr {
            margin: 0.35rem 0 0.2rem 0;
        }

        span {
            color: #0483ff;
            text-align: center;
            font-family: "PingFang SC";
            font-size: 0.32rem;
            font-style: normal;
            font-weight: 600;
            line-height: 0.5rem;
        }
    }

    .screenImg2 {
        width: 5.96rem;
        height: 4.86rem;
        display: block;
        margin: 0.48rem auto 0 auto;
    }

    .content2 {
        color: #222222;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.5rem;
        margin: 0.42rem auto 0 auto;

        p {
            margin: 0;
            padding: 0;

            span {
                color: #0483ff;
                text-align: center;
                font-family: "PingFang SC";
                font-size: 0.32rem;
                font-style: normal;
                font-weight: 500;
                line-height: 0.58rem;
            }
        }

        .content2Title {
            margin: 0.6rem 0 0.2rem 0;
        }
    }

    .screenImg3 {
        width: 5.96rem;
        height: 3.96rem;
        display: block;
        margin: 0.28rem auto 0 auto;
    }

    .content3 {
        color: #222222;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.5rem;
        margin: 0.4rem auto 0 auto;

        p {
            margin: 0;
            padding: 0;

            span {
                color: #0483ff;
                text-align: center;
                font-family: "PingFang SC";
                font-size: 0.32rem;
                font-style: normal;
                font-weight: 500;
                line-height: 0.58rem;
            }
        }

        .content3p1 {
            margin-bottom: 0.15rem;
        }

        .content3p2 {
            margin-top: 0.6rem;
        }
    }

    .content4 {
        color: #222222;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.5rem;
        margin: 0.22rem auto 0 auto;
        padding-bottom: 1rem;

        p {
            margin: 0;
            padding: 0;

            span {
                color: #0483ff;
                text-align: center;
                font-family: "PingFang SC";
                font-size: 0.32rem;
                font-style: normal;
                font-weight: 500;
                line-height: 0.58rem;
            }
        }

        .content4P1 {
            margin-top: 0.6rem;
        }
    }
}

.screen5 {
    width: 100%;
    background-color: #fff;

    img {
        display: block;
        width: 100%;
        height: 5.92rem;
    }

    .img1 {
        margin-top: 0.64rem;
    }
}

.screen6 {
    margin-top: 1rem;
    width: 100%;
    height: 8.64rem;
    background-image: url("https://appu-common.oss-cn-hangzhou.aliyuncs.com/home/phone_screen5_4.webp");
    background-size: cover;
    background-repeat: no-repeat;

    img {
        width: 6.46rem;
        height: 4.3rem;
        display: block;
        margin: 0.4rem auto 0 auto;
    }
}


.footer {
    width: 100%;
    height: 6rem;
    background: #252525;
    color: #707070;
    font-family: "Alibaba PuHuiTi 2.0";
    font-size: 0.24rem;
    font-style: normal;
    font-weight: 55 Regular;
    line-height: normal;
    p{
        margin: 0;
        padding: 0;
    }

    .codeWrapper {
        padding-top: 0.68rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        .codeItem {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;

            img {
                width: 2rem;
                height: 2rem;
            }

            p {
                margin: 0;
                padding: 0;
                color: #979797;
                font-family: "Alibaba PuHuiTi 2.0";
                font-size: 0.24rem;
                font-style: normal;
                font-weight: 55 Regular;
                line-height: normal;
                margin-top: 0.16rem;
                text-align: center;
            }
        }

        .codeItem:nth-child(1) {
            margin-right: 0.4rem;
        }
    }

    .footerLine1 {
        margin: 0.64rem 0 0.2rem 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        
        span{
            position: relative;
        }
        span:nth-child(1) {
            margin-right: 0.64rem;
            &::after{
                content: '';
                position: absolute;
                height: 0.2rem;
                width: 0.02rem;
                background-color: #707070;
                right: -0.36rem;
                top: 0.06rem;
            }
        }
    }

    .customP{
        text-align: center;
    }
}